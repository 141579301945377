
const mutations = {
    
}

const actions = {

}

const getters = {

}

const state = () => ({
    
})
/*1*/
export default {
    mutations,
    getters,
    actions,
    state,
}
